import React, { useEffect, useState } from 'react';
import fetchMethodRequest from '../../config/service';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import DoInputField from './Fields/DoInputField';
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';


const AddCredentialDetails = (props) => {

    let {
        handleSubmit,
        register,
        reset,
        setValue,
        getValues,
        formState: { errors },
        control,
        watch
      } = useForm({});

      useEffect(() => {
        if (props?.userData?.userCredentials){
            let credentialsData = props?.userData?.userCredentials;
            // Set Test credentials
            setValue('testUrl', credentialsData.testCredentials.url);
            setValue('testEmail', credentialsData.testCredentials.email);
            setValue('testPassword', credentialsData.testCredentials.password);
            
            // Set Dev credentials
            setValue('devUrl', credentialsData.devCredentials.url);
            setValue('devEmail', credentialsData.devCredentials.email);
            setValue('devPassword', credentialsData.devCredentials.password);
            
            // Set Live credentials
            setValue('liveUrl', credentialsData.liveCredentials.url);
            setValue('liveEmail', credentialsData.liveCredentials.email);
            setValue('livePassword', credentialsData.liveCredentials.password);
        }
      }, [setValue]); 

    const credentialFields = [
        {
            "name": "url",
            "type": "text",
            "placeholder": "URL",
            "label": " ",
            "width": 110,
            "id": "url",
        },
        {
            "name": "email",
            "type": "email",
            "placeholder": "Email / User Name",
            "label": " ",
            "width": 110,
            "id": "email",
        },
        {
            "name": "password",
            "type": "text",
            "placeholder": "Password",
            "label": " ",
            "width": 110,
            "id": "password",
        }
    ]

    const savingCredentials = (data) => {
        const payload = {
            isCredentialsUpdate: true,
            userCredentials: {
                testCredentials: {
                    url: data.testUrl || "",
                    email: data.testEmail || "",
                    password: data.testPassword || ""
                },
                devCredentials: {
                    url: data.devUrl || "",
                    email: data.devEmail || "",
                    password: data.devPassword || ""
                },
                liveCredentials: {
                    url: data.liveUrl || "",
                    email: data.liveEmail || "",
                    password: data.livePassword || ""
                }
            }
        };

        if (payload) {
            return fetchMethodRequest('PUT', `projects/${props.selectedId}`, payload)
                .then(async (response) => {
                    if (response && response.respMessage) {
                        showToasterMessage(response.respMessage, 'success');
                        closeCredentials();
                        props.activities();
                        props.closeFormModal();
                    } else if (response && response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');
                    }
                }).catch((err) => {
                    return err;
                });
        } else {
            return;
        }
    };

    const closeCredentials = () => {
        props.setShowCredentials(false);
        reset({
            devEmail: '',
            devPassword: '',
            devUrl: '',
            liveEmail: '',
            livePassword: '',
            liveUrl: '',
            testEmail: '',
            testPassword: '',
            testUrl: '',
        });
    };


    return (
        <form onSubmit={handleSubmit(savingCredentials)} autoComplete={'off'}>
            <div className='row p-3'>
                <h4 className='credentials-label'>Test Credential</h4>
                {credentialFields && credentialFields.length > 0 && credentialFields.map((item) => (
                    <div className={item.name === 'url' ? 'col-sm-12 pb-2' : 'col-sm-6 pb-2'} key={`test-${item.name}`}>
                        <Controller
                            name={`test${item.name.charAt(0).toUpperCase() + item.name.slice(1)}`}
                            control={control}
                            render={({ field, fieldState }) => (
                                <DoInputField
                                    input={field}
                                    id={`test-${item.id}`}
                                    name={`test${item.name.charAt(0).toUpperCase() + item.name.slice(1)}`}
                                    label={item.label}
                                    field={field}
                                    type={item.type}
                                    fieldState={fieldState}
                                    errors={errors}
                                    placeholder={item.placeholder}
                                    isDisable={item.isDisable}
                                />
                            )}
                        />
                    </div>
                ))}

                <h4 className='credentials-label mt-3'>Dev Credential</h4>
                {credentialFields && credentialFields.length > 0 && credentialFields.map((item) => (
                    <div className={item.name === 'url' ? 'col-sm-12 pb-2' : 'col-sm-6 pb-2'} key={`dev-${item.name}`}>
                        <Controller
                            name={`dev${item.name.charAt(0).toUpperCase() + item.name.slice(1)}`}
                            control={control}
                            render={({ field, fieldState }) => (
                                <DoInputField
                                    input={field}
                                    id={`dev-${item.id}`}
                                    name={`dev${item.name.charAt(0).toUpperCase() + item.name.slice(1)}`}
                                    label={item.label}
                                    field={field}
                                    type={item.type}
                                    fieldState={fieldState}
                                    errors={errors}
                                    placeholder={item.placeholder}
                                    isDisable={item.isDisable}
                                />
                            )}
                        />
                    </div>
                ))}

                <h4 className='credentials-label mt-3'>Live Credential</h4>
                {credentialFields && credentialFields.length > 0 && credentialFields.map((item) => (
                    <div className={item.name === 'url' ? 'col-sm-12 pb-2' : 'col-sm-6 pb-2'} key={`live-${item.name}`}>
                        <Controller
                            name={`live${item.name.charAt(0).toUpperCase() + item.name.slice(1)}`}
                            control={control}
                            render={({ field, fieldState }) => (
                                <DoInputField
                                    input={field}
                                    id={`live-${item.id}`}
                                    name={`live${item.name.charAt(0).toUpperCase() + item.name.slice(1)}`}
                                    label={item.label}
                                    field={field}
                                    type={item.type}
                                    fieldState={fieldState}
                                    errors={errors}
                                    placeholder={item.placeholder}
                                    isDisable={item.isDisable}
                                />
                            )}
                        />
                    </div>
                ))}
                <div className="col-sm-12 mt-2 d-flex justify-content-end">
                    <Button color='primary' type='submit' className='ms-1 me-2' title='Save' outline>
                        Save
                    </Button>
                    <Button color='primary' type='button' className='ms-2' title='Close' outline onClick={() => closeCredentials()}>
                        Close
                    </Button>
                </div>
            </div>
        </form>
    );

}
export default AddCredentialDetails;