import React, { useEffect, useState, useRef } from "react";
import fetchMethodRequest from "../../../config/service";
import moment from 'moment';
import config from "../../../config/config";
import configImages from "../../../config/configImages";
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Dialog } from 'primereact/dialog';
import DoFileUpload from "../../Form/Fields/DoFileUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import showToasterMessage from "../../UI/ToasterMessage/toasterMessage";
import ActivityComments from "./KanbanActivity";
import { Skeleton } from 'primereact/skeleton';
import { Tooltip } from 'primereact/tooltip';

const TaskDetailsDialog = (props) => {
  let [addDescriptionAndAttachments, setAddDescriptionAndAttachments] = useState(false)
  let [isDescriptionAndAttachments, setIsDescriptionAndAttachments] = useState("")
  let [suggestions, setSuggestions] = useState([]);
  let type = "tasks"
  const [setNote, setNotes] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [tempValue, setTempValue] = useState(null);
  const [values, setValues] = useState([])
  const [editingField, setEditingField] = useState(null);
  const [errors, setErrors] = useState({});
  const [trimHeader, setTrimHeader] = useState('')
  const [changeText, onChangeText] = useState('')
  const [commentValue, setCommentValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const editorRef = useRef(null);
  const valueRef = useRef(null);
  const commentsRefreshRef = useRef(null);

  let {
    control,
    setValue
  } = useForm({});

  let taskFormFields = [
    {
      name: "projectId",
      fieldName: "projectId",
      type: "relateAutoComplete",
      placeholder: "Project name",
      value: "",
      label: "Project",
      id: "projectId",
      required: true,
      searchField: "name",
      fieldType: "relateAutoComplete",
      populteFields: [],
      displayFields: ["ProjectName"],
      controllerName: "Project",
      searchApi: "projects",
      filterCriteria: { key: 'status', value: 'Active', type: 'eq' },
      isMultiple: false,
      show: true,
      isEditFieldDisable: true,
      dependent: { key: 'showsTaskId', value: 'Yes', field: 'taskID' },
    },
    {
      name: "name",
      fieldName: "name",
      type: "textarea",
      placeholder: "Task Summary",
      value: "",
      label: "Task Summary",
      required: true,
      show: true,
    },
    {
      name: "priority",
      fieldName: "priority",
      type: "dropDown",
      placeholder: "Priority",
      value: "",
      label: "Priority",
      id: "priority",
      required: false,
      options: [
        { label: "Blocker", value: "blocker", color: "primary" },
        { label: "Major", value: "major", color: "primary" },
        { label: "Minor", value: "minor", color: "primary" },
        { label: "Critical", value: "critical", color: "primary" },
      ],
      fieldType: "dropDown",
      filterElement: [
        { label: "Blocker", value: "Blocker", color: "primary" },
        { label: "Major", value: "Major", color: "primary" },
        { label: "Minor", value: "Minor", color: "primary" },
        { label: "Critical", value: "Critical", color: "primary" },
      ],
      show: true,
    },
    {
      name: "priorityNumber",
      type: "number",
      placeholder: "priorityNumber",
      label: "Priority Number",
      required: false,
      show: true,
    },
    {
      name: "linkedTask",
      type: "relateAutoComplete",
      placeholder: "Linked Task",
      label: "Linked Task",
      id: "linkedTask",
      required: false,
      globalSearchField: "false",
      controllerId: "Project",
      searchField: "name",
      fieldType: "relateAutoComplete",
      populteFields: [],
      controllerName: "Project",
      searchApi: "tasks",
      filterCriteria: { key: 'status', value: 'Active', type: 'eq' },
      isMultiple: false,
      show: true,
      dependent: { key: 'showsTaskId', value: 'Yes', field: 'taskID' },
      templateField: 'taskID',
      hasDependency: true,
      fieldForKey: "projectId",
      hasDependencyField: "projectId",
      isShowItemTemplate: true,
    },
    {
      name: "type",
      fieldName: "type",
      type: "dropDown",
      placeholder: "Type",
      value: "new",
      label: "Type",
      id: "type",
      required: true,
      options: [
        { label: "New", value: "new", color: "primary" },
        { label: "Defect", value: "defect", color: "primary" },
      ],
      fieldType: "dropDown",
      filterElement: [
        { label: "New", value: "new", color: "primary" },
        { label: "Defect", value: "defect", color: "primary" },
      ],
      show: true
    },
    {
      name: "assignedTo",
      fieldName: "assignedTo",
      type: "dropDown",
      placeholder: "Assigned To",
      value: "",
      label: "Assigned To",
      id: "assignedTo",
      required: false,
      show: true,
    },
    {
      name: "taskID",
      fieldName: "taskID",
      type: "uniqueID",
      placeholder: "Task ID",
      value: "",
      label: "Task ID",
      id: "taskID",
      required: false,
      uniqueIdLength: "6",
      show: true,
      isEditFieldDisable: true,
    },
    {
      name: "taskStatus",
      fieldName: "taskStatus",
      type: "dropDown",
      placeholder: "TaskStatus",
      value: "New",
      label: "Task Status",
      id: "taskStatus",
      required: false,
      options: [
        { label: "New", value: "new", color: "primary" },
        { label: "In Development", value: "in development", color: "secondary" },
        { label: "Dev Completed", value: "dev completed", color: "success" },
        { label: "Analysis/Design", value: "analysis/design", color: "primary" },
        { label: "Review Completed", value: "review completed", color: "light" },
        { label: "Need Clarification", value: "need clarification", color: "dark" },
        { label: "Reopen", value: "reopen", color: "danger" },
        { label: "Resolved", value: "resolved", color: "info" },
        { label: "Moved to Prod", value: "moved to prod", color: "primary" },
        { label: "On Hold", value: "onhold", color: "primary" },
        { label: "Future Implementation", value: "future implementation", color: "primary" },
        { label: "Unable to Replicate", value: "Unable to Replicate", color: "primary" },
        { label: "Duplicate", value: "duplicate", color: "primary" },
        { label: "Not an Issue", value: "not an issue", color: "primary" },
      ],
      altOptions: [
        { label: "In Development", value: "in development", color: "secondary" },
        { label: "Dev Completed", value: "dev completed", color: "success" },
        { label: "Review Completed", value: "review completed", color: "light" },
        { label: "Ready For Testing", value: "ready for testing", color: "warning", },
      ],
      filterElement: [
        { label: "New", value: "new", color: "primary" },
        { label: "In Development", value: "in development", color: "secondary" },
        { label: "Dev Completed", value: "dev completed", color: "success" },
        { label: "Analysis/Design", value: "analysis/design", color: "primary" },
        { label: "Review Completed", value: "review completed", color: "light" },
        { label: "Ready For Testing", value: "ready for testing", color: "warning", },
        { label: "Need Clarification", value: "need clarification", color: "dark" },
        { label: "Reopen", value: "reopen", color: "danger" },
        { label: "Resolved", value: "resolved", color: "info" },
        { label: "Moved to Prod", value: "moved to prod", color: "primary" },
        { label: "On Hold", value: "onhold", color: "primary" },
        { label: "Future Implementation", value: "future implementation", color: "primary" },
        { label: "Unable to Replicate", value: "Unable to Replicate", color: "primary" },
        { label: "Duplicate", value: "duplicate", color: "primary" },
        { label: "Not an Issue", value: "not an issue", color: "primary" },
      ],
      dependent: [{ "reopen": ["reopenComments"] }],
      show: true,
    },
    {
      name: "screen",
      fieldName: "screen",
      type: "text",
      placeholder: "Screen",
      value: "",
      label: "Screen/Module",
      width: "130px",
      required: false,
      show: true,
    },
    {
      name: "startDate",
      fieldName: "startDate",
      type: "date",
      placeholder: "StartDate",
      value: "",
      label: "Start Date",
      id: "startDate",
      required: false,
      dateFormat: "DD-MM-YYYY",
      show: true,
    },
    {
      name: "endDate",
      fieldName: "endDate",
      type: "date",
      placeholder: "endDate",
      value: "",
      label: "End Date",
      id: "endDate",
      required: false,
      dateFormat: "DD-MM-YYYY",
      show: true,
    },
    {
      name: "estimatedHours",
      fieldName: "estimatedHours",
      type: "number",
      placeholder: "Estimated Hours",
      value: "",
      label: "Estimated Hours",
      required: false,
      show: true,
    },
    {
      name: "actualWorkingHours",
      fieldName: "actualWorkingHours",
      type: "text",
      placeholder: "Worked Hours",
      value: "",
      label: "Worked Hours",
      required: false,
      show: true,
      isEditFieldDisable: true
    },
    {
      name: "sprint",
      fieldName: "sprint",
      type: "relateAutoComplete",
      placeholder: "Sprint Name",
      value: "",
      label: "Sprint Name",
      required: false,
      hasDependency: "true",
      searchField: "name",
      fieldType: "relateAutoComplete",
      displayFields: [],
      controllerName: "sprint",
      filterCriteria: { key: 'status', value: ['Not Started', 'In Progress', 'Dev Completed'], type: 'in' },
      searchApi: "sprints",
      isMultiple: false,
      hasDependencyField: "projectId",
      fieldForKey: "projectId",
      show: true,
    },
    {
      name: "reopenComments",
      fieldName: "reopenComments",
      type: "textarea",
      placeholder: "Reopen Comments",
      value: "",
      label: "Reopen Comments",
      required: false,
      show: false,
    },
    {
      name: "meetingDate",
      fieldName: "meetingDate",
      type: "relateAutoComplete",
      placeholder: "Meeting Date",
      value: "",
      label: "Meeting Date",
      required: false,
      searchField: "meetingDate",
      fieldType: "relateAutoComplete",
      populteFields: [],
      controllerName: "Project",
      searchApi: "projects",
      filterCriteria: { key: 'status', value: 'Active', type: 'eq' },
      hasDependencyField: "projectId",
      fieldForKey: "_id",
      show: true,
    },
    {
      name: "taskCompletionPercent",
      type: "number",
      placeholder: "Percentage of Task Completed",
      label: "Task Completed %",
      width: "120px",
      required: false,
      show: true,
    },
    {
      name: "image",
      fieldName: "image",
      type: "fileUpload",
      placeholder: "Attatch File",
      value: "",
      label: "Attach File",
      actionsNumber: [],
      id: "image",
      required: false,
      fieldType: "fileUpload",
      fileType: "img/video",
      maxFileSize: 100,
      isMultipleRequired: true,
      imagePath: "Task",
      show: false,
    },
    {
      name: "description",
      fieldName: "description",
      type: "ckeditor",
      placeholder: "Description",
      value: "",
      label: "Description",
      id: "description",
      required: false,
      show: false,
    }
  ];

  useEffect(() => {
    settingTasks()
    let filterCriteria = { limit: 30, page: 1, sortfield: "created", direction: "desc", criteria: [{ "key": '_id', "value": `${props.neededTaskID.projectId._id}`, "type": 'eq' }, { "key": "status", "value": "Active", "type": "eq" }] };
    let url = `projects?filter=${JSON.stringify(filterCriteria)}`;
    fetchMethodRequest('GET', url)
      .then((response) => {
        if (response) {
          const options = response.projects[0].assignEmployees.map(item => ({
            label: item.displayName,
            value: { displayName: item.displayName, _id: item._id }
          }));
          
          // adding the team lead in the option
          const teamLeadOption = {
            label: response.projects[0].teamLead.displayName,
            value: { 
              displayName: response.projects[0].teamLead.displayName, 
              _id: response.projects[0].teamLead._id 
            }
          };
          
          options.push(teamLeadOption);
          
          setDropdownOptions(options);
        }
      }).catch((err) => {
        return err;
      });

  }, [props])

  let settingTasks = () => {
    setIsLoading(true);
    let filterCriteria = { limit: 20, page: 1, criteria: [{ key: "_id", value: props.neededTaskID._id, type: "eq" }], direction: 'desc', sortfield: 'created' };
    let apiUrl = `tasks?filter=${JSON.stringify(filterCriteria)}`;
    fetchMethodRequest('GET', apiUrl)
      .then((response) => {
        if (response) {
          let updatedformfields = taskFormFields.map((field) => {
            const key = field.name;
            const value = response.tasks[0][key];
            const newValue = !value ? "" : value;

            if (field.name === "image") {
              setValue(field.name, newValue);
            }

            // updated fields with the values
            let updatedField = {
              ...field,
              value: newValue,
            };

            return updatedField;
          });

          // to handke the dependency for the reopen comments field
          updatedformfields = handleFieldDependencies(updatedformfields);

          setValues(updatedformfields);
          setTrimHeader(updatedformfields[1].value.length > 90 ? updatedformfields[1].value.substring(0, 90) + '...' : updatedformfields[1].value);
          setIsLoading(false);
        }
      }).catch((err) => {
        return err;
      });
  };

  const handleFieldDependencies = (fields) => {
    const taskStatusField = fields.find(field => field.name === "taskStatus");

    if (taskStatusField) {
      const isReopen = taskStatusField.value?.toLowerCase() === "reopen";

      // updating the field based on dependencies
      return fields.map(field => {
        if (field.name === "reopenComments") {
          return {
            ...field,
            show: isReopen, // set the show in reopen comment true when the taskStatus is "reopen"
          };
        }
        return field;
      });
    }

    return fields;
  };

  let markRequired = (field) => {
    return (
      <FontAwesomeIcon
        color='gray'
        icon='plus-circle'
        title="Add More"
        onClick={() => {
          setAddDescriptionAndAttachments(true);
          setIsDescriptionAndAttachments(field)
        }}
      />
    );
  };

  let getFileUpload = (val) => {
    let item = taskFormFields[18];
    const bytesPerMB = 1024 * 1024;
    let fileSize = item.maxFileSize * bytesPerMB;
    let otherFormats = ".pdf, .doc, .docx, .xls, .xlsx, .csv, .ppt, .json, .txt"
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoFileUpload
              url={`uploads/uploadAttachments?type=${item.imagePath.toLowerCase()}`}
              imagePath={item.imagePath.toLowerCase()}
              showPreview={true}
              acceptType={item.fileType === 'img/video' ? "image/*, video/*" : item.fileType === 'other' ? otherFormats : '*'}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
              type={"tasks"}
              isMultiple={item.isMultipleRequired}
              maxFileSize={fileSize}
              handleSave={handleSave}
              item={item}
              forKanban={true}
            />)}
        />
      </div>
    )
  }

  const deleteFile = (img, newFiles) => {
    let allFiles = newFiles.value.filter(file => file !== img)
    handleSave(newFiles, allFiles)
  };

  const validateField = (field, value) => {
    let error = "";
    if ((field === "input1" || field === "input2") && value.trim() === "") {
      error = "This field is required.";
    } else if (
      field === "number" &&
      (isNaN(value) || value <= 0 || value > 100)
    ) {
      error = "Enter a number between 1 and 100.";
    }
    return error;
  };

  const handleSave = (field, value) => {
    let payload;
    if (
      (field.type === "relateAutoComplete" || field.name === "assignedTo") &&
      typeof value === "object"
    ) {
      payload = {
        [field.name]: value,
        [field.name + "Search"]: value[field.searchField],
      };
    }else if (
      field.type === "relateAutoComplete" && value === ""
    ) {
      payload = {
        [field.name]: null,
        [field.name + "Search"]: null,
      };
    } else {
      payload = {
        [field.name]: value,
      };
    }

    if (value !== undefined) {
      const url = `tasks/${props.neededTaskID._id}`;
      fetchMethodRequest("PUT", url, payload)
        .then((response) => {
          if (response?.respCode) {
            showToasterMessage(response.respMessage, "success");

            if (field.name === "taskStatus") {
              // updating the values to show/hide dependent fields
              setValues(prevValues => {
                const updatedValues = prevValues.map(prevField => {
                  if (prevField.name === "taskStatus") {
                    return { ...prevField, value };
                  }
                  return prevField;
                });

                return handleFieldDependencies(updatedValues);
              });
            } else {
              settingTasks();
            }

            setTempValue(null);
            setAddDescriptionAndAttachments(false);
            setIsDescriptionAndAttachments("");
          } else if (response?.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const getAllSuggestions = (event, field) => {
    let url;
    let apiUrl;
    let filterCriteria = {
      limit: 30,
      page: 1,
      sortfield: "created",
      direction: "desc",
      criteria: []
    };

    if (field.name === "sprint") {
      apiUrl = "sprints"
      filterCriteria['criteria'].push({ key: 'projectId', value: `${props.neededTaskID.projectId._id}`, type: 'eq' }, { key: 'status', value: ['Not Started', 'In Progress', 'Dev Completed'], type: 'in' })
      url = `${apiUrl}?searchFrom=autoComplete&filter=${JSON.stringify(filterCriteria)}`;
    }

    if (field.name === "linkedTask") {
      apiUrl = "tasks"
      filterCriteria['criteria'].push({ key: 'projectId', value: `${props.neededTaskID.projectId._id}`, type: 'eq' }, { "key": "_id", "value": props.neededTaskID, "type": "ne" }, { "key": "status", "value": "Active", "type": "eq" })
      url = `${apiUrl}?searchFrom=autoComplete&filter=${JSON.stringify(filterCriteria)}`;
    }

    if (field.name === "meetingDate") {
      apiUrl = "projects"
      filterCriteria['criteria'].push({ key: '_id', value: `${props.neededTaskID.projectId._id}`, type: 'eq' }, { "key": "status", "value": "Active", "type": "eq" })
      url = `${apiUrl}?filter=${JSON.stringify(filterCriteria)}`;
    }

    return fetchMethodRequest('GET', url)
      .then((response) => {
        if (response) {
          let dropdownData = [];
          if (response[apiUrl] && response[apiUrl].length && response[apiUrl].length > 0) {
            dropdownData = response[apiUrl];
          }
          setSuggestions(dropdownData);

          if (field.name == "meetingDate") {
            const allMeetingNotes = dropdownData.map(item => item.meetingNotes).flat();
            setNotes(allMeetingNotes)
          }
        }
      }).catch((err) => {
        return err;
      });
  }

  function formatDate(dateStr) {
    if (!dateStr) return "None";
    return moment(dateStr).format('DD-MM-YYYY');
  }


  const chooseColour = (status) => {
    switch (status) {
      case "new":
        return "#326fd1";
      case "in development":
        return "#9fa9b7";
      case "dev completed":
        return "#198754";
      case "analysis/design":
        return "#00FF00";
      case "review completed":
        return "#6366f1";
      case "ready for testing":
        return "#eec137";
      case "need clarification":
        return "#FF00FF";
      case "reopen":
        return "red";
      case "resolved":
        return "#0dcaf0";
      case "moved to prod":
        return "#00FFFF";
      case "onhold":
        return "#000000";
      case "future implementation":
        return "#000080";
      case "unable to replicate":
        return "#A52A2A";
      case "duplicate":
        return "#808080";
      case "not an issue":
        return "#FFA500";
      case "defect":
        return "#9fa9b7";
      case "blocker":
        return "#EEC137";
      case "major":
        return "#198754";
      case "minor":
        return "#326fd1";
      case "critical":
        return "#FF0000";
      default:
        return "gray";
    }
  };

  const itemTemplate = (item) => {
    let name = item.name;
    return (
      <div className="country-item">
        <div><span style={{ color: 'seagreen' }}>{item.taskID}</span> - {name}</div>
      </div >
    );
  };

  const renderField = (type, value, field) => {
    if (editingField === field.name) {
      switch (type) {
        case "text":
          return (
            <InputText
              value={tempValue !== null ? tempValue : field.value}
              onChange={(e) => setTempValue(e.target.value)}
              onBlur={() => {
                handleSave(field, tempValue !== null ? tempValue : field.value);
                setEditingField(null);
              }}
              className={`${errors[field] ? "p-invalid" : ""}`}
              style={{ width: '65%', height: '40px' }}
            />
          );
        case "number":
          return (
            <InputNumber
              value={tempValue !== null ? tempValue : field.value}
              onValueChange={(e) => {
                setTempValue(e.value)
                valueRef.current = e.value;
              }}
              onBlur={() => {
                handleSave(field, valueRef?.current === null ? 0 : valueRef?.current ? valueRef?.current : field.value);
                setEditingField(null);
              }}
              className={`${errors[field] ? "p-invalid" : ""}`}
              style={{ width: '65%', height: '40px' }}
            />
          );
        case "dropDown":
          return (
            <Dropdown
              value={field.value}
              options={field.name === "assignedTo" ? dropdownOptions : field.options}
              onChange={(e) => handleSave(field, e.value)}
              onBlur={() => setEditingField(null)}
              placeholder="Select"
              style={{ width: '65%', height: '40px' }}
            />
          );
        case "textarea":
          return (
            <InputTextarea
              value={tempValue !== null ? tempValue : field.value}
              onChange={(e) => setTempValue(e.target.value)}
              onBlur={() => {
                handleSave(field, tempValue !== null ? tempValue : field.value);
                setEditingField(null);
                setTempValue(null); // Reset tempValue after saving
              }}
              rows={3}
              cols={30}
              autoResize
              style={{ width: '65%' }}
            />
          );
        case "date":
          return (
            <Calendar
              value={new Date(field.value)}
              onChange={(e) => { handleSave(field, e.value); setEditingField(null) }}
              // onBlur={() => setEditingField(null)}
              placeholder="Select Date"
              showIcon
              style={{ width: '65%', height: '40px' }}
            />
          );
        case "relateAutoComplete":
          return (
            <AutoComplete
              value={field.value}
              suggestions={field.name == "meetingDate" ? setNote : suggestions}
              completeMethod={(e) => getAllSuggestions(e, field)}
              onChange={(e) => { handleSave(field, e.value); setEditingField(null) }}
              // onBlur={() => setEditingField(null)}
              field={field.searchField}
              placeholder={field.placeholder}
              dropdown={true}
              panelStyle={{ width: '100px' }}
              style={{ width: '65%' }}
              itemTemplate={field.isShowItemTemplate ? itemTemplate : false}
            />
          );
        default:
          return null;
      }
    }

    return (
      <span onClick={() => field?.isEditFieldDisable ? "" : setEditingField(field.name)}
        style={{
          cursor: field?.isEditFieldDisable ? "auto" : "pointer",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "inline-block",
          maxWidth: "225px"
        }}
        title={typeof value === 'object' ? value.name || value.displayName : value}
      >
        {
          (() => {
            if (type === "relateAutoComplete" && typeof value === 'object' && value !== null) {
              return value.name || value.meetingDate || "None";
            }

            if (field.name === "taskCompletionPercent") {
              if (value) {
                return value + '%';
              } else {
                return '0%';
              }
            }

            if (field.name === "assignedTo" && typeof value === 'object' && value) {
              return value.displayName;
            }

            if (type === "dropDown" && field.name !== "assignedTo" && value) {
              const color = chooseColour(value);
              return <span className="p-badge" style={{ backgroundColor: color }}>{value}</span>;
            }

            if (type === "date") {
              return formatDate(value);
            }

            if (!value) {
              return "None";
            }

            return typeof value === 'object' ? JSON.stringify(value) : value;
          })()
        }
      </span>
    );
  };

  const checkImg = (img) => {
    if (img) {
      return config.imageFormats.some(x => img.toLowerCase().includes(x));
    }
    return false;
  };

  const checkVid = (img) => {
    if (img) {
      return config.videoFormats.some(x => img.toLowerCase().includes(x));
    }
    return false;
  };

  const closeModal = () => {
    props.setVisibleTaskDetails(false)
    setEditingField(null)
    if (typeof props.getActivities === "function") {
      props.getActivities();
    }

    if (typeof props.onClickSave === "function" ) { 
      props.onClickSave("", props.sprintId )
    }
  }

  const sendCommentsToServer = () => {
    const data = {
      eventType: "COMMENTS",
      comments: commentValue,
      contextId: { task: props.neededTaskID._id },
      contextType: "task"
    };

    fetchMethodRequest('POST', 'events', data)
      .then(response => {
        if (response && response.respCode) {
          showToasterMessage(response.respMessage, 'success');
          setCommentValue('');
          if (commentsRefreshRef.current) {
            commentsRefreshRef.current();
          }
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
        }
      })
      .catch(err => {
        console.error("Error sending comment:", err);
      });
  };

  let getEditorField = () => {
    return (
      <div className="d-flex align-items-center mt-4 mb-3">
        <InputTextarea
          autoResize
          value={commentValue}
          placeholder="Add a comment..."
          onChange={(e) => setCommentValue(e.target.value)}
          rows={1}
          cols={50}
          className="flex-grow-1 add-comments-Kanban"
        />
        <FontAwesomeIcon
          className={`send-comment-icon ${commentValue === "" ? "disabled-icon" : ""}`}
          icon='paper-plane'
          title="Save Comment"
          disabled={commentValue === "" ? true : false}
          onClick={commentValue === "" ? undefined : sendCommentsToServer}
        />
      </div>
    )
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the CKEditor container
      if (editorRef.current && !editorRef.current.contains(event.target)) {
        handleSave(values[19], changeText);
        setEditingField(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [changeText, handleSave, values, setEditingField]);

  return (
    <>
      <div className="card flex justify-content-center">
        <Dialog
          visible={props.visibleTaskDetails}
          modal
          header={
            <div id="dialog-header" data-pr-tooltip={`${values[1]?.value}`} data-pr-position="mouse">
              {`${props.neededTaskID.taskID} : ${trimHeader}`}
            </div>
          }
          style={{ width: '70rem' }}
          onHide={closeModal}
        >
          <Tooltip target="#dialog-header" mouseTrack />

          {isLoading ?
            (<div className="task-edit-view-container">
              <div className="left-panel">
                <h2>Details</h2>
                <div className="section">
                  <div className="inline-elements">
                    <h3>Description</h3>
                  </div>
                  <div className="mt-3 mb-3">
                    <Skeleton width="100%" height="150px"></Skeleton>
                  </div>
                </div>
                <div className="section">
                  <div className="inline-elements">
                    <h3>Attachments</h3>
                  </div>
                  <div className="w-full md:w-6 p-3">
                    <div className="flex align-items-end gap-3">
                      {Array(6).fill().map((_, index) => (
                        <Skeleton key={`field-${index}`} size="5rem"></Skeleton>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="section">
                  <div className="inline-elements">
                    <h3>Activities</h3>
                  </div>
                  <div className="w-full p-3">
                    <div className="flex p-1 gap-2 mb-2">
                      <Skeleton width="4rem" height="2rem"></Skeleton>
                      <Skeleton width="4rem" height="2rem"></Skeleton>
                    </div>
                    <Skeleton width="100%" height="150px"></Skeleton>
                  </div>
                  <div className="w-full mt-3">
                    <Skeleton height="3rem"></Skeleton>
                  </div>
                </div>
              </div>
              <div className="right-panel">
                <h2>Edit Details</h2>
                {Array(10).fill().map((_, index) => (
                  <div key={`field-${index}`} className="flex gap-3 mb-3">
                    <Skeleton width="35%" height="2.5rem" />
                    <Skeleton width="65%" height="2.5rem" />
                  </div>
                ))}
              </div>
            </div>)
            :
            (<div className="task-edit-view-container">
              <div className="left-panel">
                <h2>Details</h2>
                <div className="section">
                  <div className="inline-elements">
                    <h3>Description</h3>
                    {markRequired("description")}
                  </div>
                  {addDescriptionAndAttachments && isDescriptionAndAttachments === "description" ?
                    <div className="mt-2 mb-3" ref={editorRef}>
                      <CKEditor
                        editor={ClassicEditor}
                        data={values[19].value ? values[19].value : null}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          onChangeText(data);
                        }}
                      />
                    </div> :
                    <p className="p-2" dangerouslySetInnerHTML={{ __html: values[19]?.value }} />
                  }
                </div>
                <div className="section">
                  <div className="inline-elements">
                    <h3>Attachments</h3>
                    {getFileUpload(values)}
                  </div>
                  {values[18]?.value && (
                    <div className="kanban-view-image-container">
                      <div className="kanban-view-image-grid">
                        {values[18]?.value.map((img, index) => (
                          <div key={index} className="kanban-view-image-card"
                            onMouseOver={(e) => {
                              e.currentTarget.style.transform = 'translateY(-2px)';
                              e.currentTarget.style.boxShadow = '0 4px 8px rgba(9, 30, 66, 0.25)';
                            }}
                            onMouseOut={(e) => {
                              e.currentTarget.style.transform = 'none';
                              e.currentTarget.style.boxShadow = 'none';
                            }}
                          >
                            <Link to={`${config.imgUrl}${type?.toLowerCase()}/${img}`} target="_blank" className="kanban-view-image-link">
                              {checkVid(img) ? (
                                <img
                                  src={configImages?.[img?.split('.')?.[img?.split('.')?.length - 1]]}
                                  className="kanban-view-image-thumbnail"
                                  alt=""
                                />
                              ) : checkImg(img) ? (
                                <img
                                  src={config.imgUrl + `${type?.toLowerCase()}/` + img}
                                  className="kanban-view-image-thumbnail"
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={configImages?.[img?.split('.')?.[img?.split('.')?.length - 1]]}
                                  className="kanban-view-image-thumbnail"
                                  alt=""
                                />
                              )}
                            </Link>
                            <div className="kanban-view-image-footer">
                              <div className="kanban-view-image-details">
                                <span className="kanban-view-image-name" title={img}>
                                  {img}
                                </span>
                                <i
                                  title="Delete Image"
                                  className="pi pi-trash kanban-view-delete-icon"
                                  onClick={() => deleteFile(img, values[18])}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="section">
                  <div className="inline-elements">
                    <h3>Activities</h3>
                  </div>
                  <ActivityComments
                    selectedData={props.neededTaskID}
                    refreshRef={commentsRefreshRef}
                  />
                  <div>
                    {getEditorField()}
                  </div>
                </div>
              </div>
              <div className="right-panel">
                <h2>Edit Details</h2>
                {values?.filter(field => field?.show)
                  .map((field) => (
                    <div className="form-field" key={field.name}>
                      <div className="form-field-row">
                        <label onClick={() => setEditingField(null)}>{field.label}</label>
                        {renderField(field.type, field.value, field)}
                      </div>
                      {/* {errors[field.fieldName] && <small className="p-error">{errors[field.fieldName]}</small>} */}
                    </div>
                  ))}
              </div>
            </div>)
          }
        </Dialog>
      </div>
    </>
  )

}
export default TaskDetailsDialog;