import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

const ApproveOrReject = (props) => {
    const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
    const [isOpenRejectModal, setIsOpenRejectModal] = useState(false);
    const [rejectReason, setRejectReason] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); // Step 1

    const item = props.item;
    const filterCriteria = props.filterCriteria;
    const screen = props.type;

    let closeRejectModal = () => {
        setIsOpenRejectModal(false)
    }

    let handleInputChange = (event) => {
        setRejectReason(event.target.value);
    };

    let closeConfirmationModal = () => {
        setIsOpenConfirmationModal(false)
    }

    let saveDataToServer = (type, reason) => {
        if (isSubmitting) {
            return;
        }
        setIsSubmitting(true);
        

        for (let key in item) {
            if (typeof item[key] === 'string' && item[key].match(/^\d{2}-\d{2}-\d{4}$/)) {
                let [day, month, year] = item[key].split('-');
                item[key] = `${year}-${month}-${day}`;
            }
        }
        if (reason) {
            item.status = type;
            item.rejectReason = reason;
        } else {
            item.status = type;
        }
        let userBody = reason ? item : { status: type };

        return fetchMethodRequest('PUT', `${screen}/${item._id}`, userBody)
            .then(async (response) => {
                if (response) {
                    closeConfirmationModal();
                    closeRejectModal();
                    if (props.approvalpage) {
                      props.getDataFromServer(
                        filterCriteria,
                        "",
                        "",
                        "approvals"
                      );
                    } else {
                      props.getDataFromServer(filterCriteria);
                    }
                    showToasterMessage(response.respMessage, 'success');
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
            }).catch((err) => {
                return err;
            });
    }

    let getApproveModal = () => {
        return (
            <Modal isOpen
                className={`modal-dialog-centered`} style={{ maxWidth: 500 }}>
                <ModalBody>
                    <div style={{ marginBottom: 20 }}>{'Are you sure want to Approve ?'}</div>
                    <Button color="primary" outline onClick={closeConfirmationModal} className='deleteModalBtn marginRight'>{'No'}</Button>
                    <Button color="primary" outline onClick={() => saveDataToServer('Approved')} disabled={isSubmitting} 
                        className='deleteModalBtn'>{'Yes'}</Button>
                </ModalBody>
            </Modal >
        )
    }

    let getRejectModal = () => {
        return (
            <Modal isOpen className={`modal-dialog-centered modal-dialog--primary modal-dialog--header `} style={{ maxWidth: "35%" }}>
                <ModalHeader className="modal__header">
                    <button className="lnr lnr-cross modal__close-btn" type="button" onClick={() => closeRejectModal()} />
                    <p className="bold-text modal__title"> {'Reason'} </p>
                </ModalHeader>
                <ModalBody >
                    <div className='form__form-group' style={{ display: "flex", margin: "20px" }}>
                        <span className="form__form-group-label" style={{ margin: '50px' }}>{'Reason'}</span>
                        <div className="form__form-group-field">
                            <textarea
                                name='rejectReason'
                                id='reason'
                                placeholder='RejectReason'
                                value={rejectReason}
                                onChange={handleInputChange}
                                style={{ width: '60%', height: '100px' }}
                            />
                        </div>
                    </div>
                    <div className='text-center mt-3'>
                        <Button color='primary' outline onClick={closeRejectModal}>{'Close'}</Button>
                        <Button color='primary' outline onClick={() => saveDataToServer('Rejected', rejectReason)}>{'Save'}</Button>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    return (
        <>
            <span>
                <FontAwesomeIcon
                    className='genderIconAlignment'
                    color='white'
                    icon='ban'
                    data-toggle="tool-tip"
                    title="Reject"
                    style={{ color: '#024a88', width: '15', marginLeft: 10, cursor: 'pointer' }}
                    onClick={() => setIsOpenRejectModal(true)}
                />
                <FontAwesomeIcon
                    className='genderIconAlignment'
                    color='white'
                    icon='check-circle'
                    data-toggle="tool-tip"
                    title="Approve"
                    style={{ color: 'green', width: '15', marginLeft: 10, cursor: 'pointer' }}
                    onClick={() => setIsOpenConfirmationModal(true)}
                />
            </span>
            {isOpenConfirmationModal ? getApproveModal() : null}
            {isOpenRejectModal ? getRejectModal() : null}
        </>
    );

}
export default ApproveOrReject;