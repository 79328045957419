import React, { useEffect, useState } from 'react';
import fetchMethodRequest from '../../../config/service';

const TopbarNotification = () => {
    const [notifications, setNotifications] = useState([]);
    const loginRole = JSON.parse(localStorage.getItem('loginCredentials'));

    useEffect(() => {
        const fetchData = async () => {
            if (loginRole?.roleType !== 'Employee') {
                await getNotification();
            }

            if (loginRole?.roleType !== 'Admin') {
                await enablePreviousTimesheetFn();
            }
        };

        // Initial fetch
        fetchData();

        // Set up interval for 1 minute
        const intervalId = setInterval(fetchData, 60000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const getNotification = async () => {
        try {
            const response = await fetchMethodRequest('GET', `Notifications/listNotifications`);
            if (response?.notifications) {
                setNotifications(response.notifications);
            }
        } catch (error) {
            console.error("Error fetching notifications:", error);
        }
    };

    const enablePreviousTimesheetFn = async () => {
        try {
            const response = await fetchMethodRequest('GET', `employees/getEnableTimeSheetsKey/${loginRole._id}`);

            if (response?.enablePreviousTimesheet) {
                // Check if the notification already exists before adding it
                setNotifications((prevNotifications) => {
                    const notificationExists = prevNotifications.some(
                        notification => notification.type === "Enable Previous Timesheets"
                    );

                    if (!notificationExists) {
                        const newNotification = {
                            type: "Enable Previous Timesheets",
                            Names: ["You can now fill timesheets for previous dates"]
                        };
                        return [...prevNotifications, newNotification];
                    }
                    return prevNotifications;
                });
            } else {
                // If enablePreviousTimesheet is false, we want to refresh from getNotification
                if (loginRole?.roleType !== 'Employee') {
                    await getNotification();
                }else{
                    setNotifications([]);
                }
            }
        } catch (error) {
            console.error("Error fetching timesheet status:", error);
            // On error, fall back to getNotification
            if (loginRole?.roleType !== 'Employee') {
                await getNotification();
            }
        }
    };

    return (
        notifications.length > 0 && (
            <div className="notification-box">
                <span className="notification-count">{notifications.length}</span>
                <div className="notification-bell">
                    <span className="bell-top"></span>
                    <span className="bell-middle"></span>
                    <span className="bell-bottom"></span>
                    <span className="bell-rad"></span>
                </div>

                {/* Notification Details Container */}
                <div className="notification-details">
                    <div className="notification-list">
                        {notifications.map((notification, index) => (
                            <div key={index} className="notification-item">
                                <div className="notification-type">
                                    {notification.type}&nbsp;
                                    <span>
                                        {notification.type === "Pending Leave Approvals"
                                            ? "🏖️🏝️✈️"
                                            : notification.type === "Pending Timesheet Approvals"
                                                ? "⏳📅📝"
                                                : "✅🔓"}
                                    </span>
                                </div>
                                <div className="notification-names">
                                    {notification.Names?.filter(Boolean).join(', ')}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    );

};

export default TopbarNotification;