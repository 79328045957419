import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const LeaveHistories = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  let dataTableRef = useRef(null);
  const params = useParams();
  useEffect(() => {
    let screenPermissions =
      RolePermissions.screenPermissions("Leave Histories");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: "120px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Created By",
        derivedValue: "createdByName=createdBy=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: "employee",
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      // {
      //   name: "updatedByName",
      //   fieldName: "updatedByName",
      //   type: "text",
      //   placeholder: "Updated By",
      //   value: "",
      //   label: "Updated By",
      //   width: "120px",
      //   addFormOrder: 3,
      //   editFormOrder: 3,
      //   header: "Updated By",
      //   derivedValue: "updatedByName=updatedBy=undefined",
      //   capitalizeTableText: false,
      //   sortable: true,
      //   filter: true,
      //   id: "updatedByName",
      //   displayinaddForm: "false",
      //   displayineditForm: "false",
      //   displayinregisterForm: false,
      //   displayinlist: "false",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: "",
      //   globalSearchField: "false",
      //   controllerId: "employee",
      //   isClickable: "",
      //   isAddToIndex: false,
      //   isBulkUploadField: false,
      //   textAlign: "center",
      //   show: false,
      //   field: "updatedByName",
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      // },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 4,
        editFormOrder: 4,
        header: "Date",
        derivedValue: "created=created=created=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      // {
      //   name: "updated",
      //   fieldName: "updated",
      //   type: "date",
      //   placeholder: "Updated",
      //   value: "",
      //   label: "Updated",
      //   width: 90,
      //   addFormOrder: 5,
      //   editFormOrder: 5,
      //   header: "Updated",
      //   derivedValue: "updated=updated=updated=updated=undefined",
      //   capitalizeTableText: "",
      //   sortable: "",
      //   filter: true,
      //   actions: [],
      //   actionsNumber: [],
      //   id: "updated",
      //   displayinaddForm: "false",
      //   displayineditForm: "false",
      //   displayinregisterForm: "",
      //   displayinlist: "false",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: "",
      //   globalSearchField: "false",
      //   controllerId: null,
      //   isClickable: "",
      //   isAddToIndex: false,
      //   isBulkUploadField: false,
      //   dateFormat: "DD-MM-YYYY",
      //   fieldType: "Date",
      //   textAlign: "center",
      //   show: false,
      //   field: "updated",
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      // },
      {
        name: "fromDate",
        fieldName: "fromDate",
        type: "date",
        placeholder: "FromDate",
        value: "",
        label: "From Date",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "From Date",
        derivedValue: "fromDate=fromDate=fromDate=FromDate=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "fromDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "fromDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "toDate",
        fieldName: "toDate",
        type: "date",
        placeholder: "ToDate",
        value: "",
        label: "To Date",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "To Date",
        derivedValue: "toDate=toDate=toDate=toDate=ToDate=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "toDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "toDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "type",
        fieldName: "type",
        type: "dropDown",
        placeholder: "Type",
        value: "",
        label: "Type",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Type",
        derivedValue: "type=type=type=Type=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Leave Create", value: "leave Create", color: "warning" },
          { label: "Leave  Updated", value: "leave Update", color: "primary" },
          { label: "leave Approved", value: "leave Approved", color: "success" },
          { label: "leave Rejected", value: "leave Rejected", color: "secondary" },
          { label: "leave Delete", value: "leave Delete", color: "danger" },
          { label: "monthlyLeaveCredit", value: "monthlyLeaveCredit", color: "light" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Leave Create", value: "leave Create", color: "warning" },
          { label: "Leave  Updated", value: "leave Update", color: "primary" },
          { label: "leave Approved", value: "leave Approved", color: "success" },
          { label: "leave Rejected", value: "leave Rejected", color: "secondary" },
          { label: "leave Delete", value: "leave Delete", color: "danger" },
          { label: "monthlyLeaveCredit", value: "monthlyLeaveCredit", color: "light" },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "type",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "numberOfDays",
        fieldName: "numberOfDays",
        type: "number",
        placeholder: "No Of Days",
        value: "",
        label: "No Of Days",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "No Of Days",
        derivedValue:
          "numberOfDays=numberOfDays=numberOfDays=numberOfDays=NumberOfDays=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "numberOfDays",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "numberOfDays",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "afterLeaves",
        fieldName: "afterLeaves",
        type: "number",
        placeholder: "After Leaves",
        value: "",
        label: "After Leaves",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "After Leaves",
        derivedValue:
          "afterLeaves=afterLeaves=afterLeaves=AfterLeaves=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "afterLeaves",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "afterLeaves",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "companyEmpId",
        fieldName: "companyEmpId",
        type: "text",
        placeholder: "CompanyEmpId",
        value: "",
        label: "CompanyEmpId",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "CompanyEmpId",
        derivedValue:
          "companyEmpId=companyEmpId=companyEmpId=companyEmpId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "companyEmpId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "companyEmpId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "displayName",
        fieldName: "displayName",
        type: "text",
        placeholder: "Name",
        value: "",
        label: "Name",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "Name",
        derivedValue:
          "name=name=name=name=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "name",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "displayName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "leaveAdjustment",
        fieldName: "leaveAdjustment",
        type: "text",
        placeholder: "Leave Adjustment",
        value: "",
        label: "Leave Adjustment",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Leave Adjustment",
        derivedValue: "Leave Adjustment=leaveAdjustment=leaveAdjustment=leaveAdjustment=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "leaveAdjustment",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1001,
        isClickable: "",
        fieldType: "text",
        isAddToIndex: "",
        textAlign: "center",
        show: true,
        field: "operation",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
     
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "employeeName",
        fieldName: "employeeName",
        type: "text",
        placeholder: "Name",
        value: "",
        label: "Name",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "employeeName=employeeName=name=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "employeeName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 4,
        editFormOrder: 4,
        derivedValue: "created=created=created=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue: "updated=updated=updated=updated=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "date",
        fieldName: "date",
        type: "date",
        placeholder: "Date",
        value: "",
        label: "Date",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "date=date=date=Date=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "date",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "fromDate",
        fieldName: "fromDate",
        type: "date",
        placeholder: "FromDate",
        value: "",
        label: "From Date",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "fromDate=fromDate=fromDate=FromDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "fromDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "toDate",
        fieldName: "toDate",
        type: "date",
        placeholder: "ToDate",
        value: "",
        label: "To Date",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "toDate=toDate=toDate=toDate=ToDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "toDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "type",
        fieldName: "type",
        type: "dropDown",
        placeholder: "Type",
        value: "",
        label: "Type",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "type=type=type=Type=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Leave Create", value: "LeaveCreate", color: "primary" },
          { label: "Leave  Updated", value: "LeaveUpdated", color: "primary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Leave Create", value: "LeaveCreate", color: "primary" },
          { label: "Leave  Updated", value: "LeaveUpdated", color: "primary" },
        ],
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "numberOfDays",
        fieldName: "numberOfDays",
        type: "number",
        placeholder: "No Of Days",
        value: "",
        label: "No Of Days",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        derivedValue:
          "numberOfDays=numberOfDays=numberOfDays=numberOfDays=NumberOfDays=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "numberOfDays",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "afterLeaves",
        fieldName: "afterLeaves",
        type: "number",
        placeholder: "After Leaves",
        value: "",
        label: "After Leaves",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue:
          "afterLeaves=afterLeaves=afterLeaves=AfterLeaves=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "afterLeaves",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "operation",
        type: "text",
        placeholder: "operation",
        label: "operation",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "operation=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "operation",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "beforeLeaves",
        type: "text",
        placeholder: "BeforeLeaves",
        label: "BeforeLeaves",
        width: "130px",
        addFormOrder: 13,
        editFormOrder: 13,
        derivedValue: "beforeLeaves=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "beforeLeaves",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "displayName",
        type: "text",
        placeholder: "DisplayName",
        label: "DisplayName",
        width: "130px",
        addFormOrder: 14,
        editFormOrder: 14,
        derivedValue: "displayName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "displayName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "companyEmpId",
        fieldName: "companyEmpId",
        type: "text",
        placeholder: "Company Emp Id",
        value: "",
        label: "Company Emp Id",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        derivedValue:
          "companyEmpId=companyEmpId=companyEmpId=companyEmpId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "companyEmpId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "reason",
        type: "text",
        placeholder: "Reason",
        label: "Reason",
        width: "130px",
        addFormOrder: 16,
        editFormOrder: 16,
        derivedValue: "reason=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "reason",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        width: "150px",
        addFormOrder: 17,
        editFormOrder: 17,
        derivedValue: "email=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "email",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "employeeName",
        fieldName: "employeeName",
        type: "text",
        placeholder: "Name",
        value: "",
        label: "Name",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Name",
        derivedValue: "employeeName=employeeName=name=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "employeeName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "employeeName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: "120px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Created By",
        derivedValue: "createdByName=createdBy=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: "employee",
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: "120px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "Updated By",
        derivedValue: "updatedByName=updatedBy=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: "employee",
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: false,
        field: "updatedByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 4,
        editFormOrder: 4,
        header: "Created",
        derivedValue: "created=created=created=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: false,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Updated",
        derivedValue: "updated=updated=updated=updated=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: false,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "date",
        fieldName: "date",
        type: "date",
        placeholder: "Date",
        value: "",
        label: "Date",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Date",
        derivedValue: "date=date=date=Date=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "date",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "date",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "fromDate",
        fieldName: "fromDate",
        type: "date",
        placeholder: "FromDate",
        value: "",
        label: "From Date",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "From Date",
        derivedValue: "fromDate=fromDate=fromDate=FromDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "fromDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "fromDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "toDate",
        fieldName: "toDate",
        type: "date",
        placeholder: "ToDate",
        value: "",
        label: "To Date",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "To Date",
        derivedValue: "toDate=toDate=toDate=toDate=ToDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "toDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "toDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "type",
        fieldName: "type",
        type: "dropDown",
        placeholder: "Type",
        value: "",
        label: "Type",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Type",
        derivedValue: "type=type=type=Type=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Leave Create", value: "LeaveCreate", color: "primary" },
          { label: "Leave  Updated", value: "LeaveUpdated", color: "primary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Leave Create", value: "LeaveCreate", color: "primary" },
          { label: "Leave  Updated", value: "LeaveUpdated", color: "primary" },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "type",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "numberOfDays",
        fieldName: "numberOfDays",
        type: "number",
        placeholder: "No Of Days",
        value: "",
        label: "No Of Days",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "No Of Days",
        derivedValue:
          "numberOfDays=numberOfDays=numberOfDays=numberOfDays=NumberOfDays=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "numberOfDays",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "numberOfDays",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "afterLeaves",
        fieldName: "afterLeaves",
        type: "number",
        placeholder: "After Leaves",
        value: "",
        label: "After Leaves",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "After Leaves",
        derivedValue:
          "afterLeaves=afterLeaves=afterLeaves=AfterLeaves=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "afterLeaves",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "afterLeaves",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "companyEmpId",
        fieldName: "companyEmpId",
        type: "text",
        placeholder: "Company Emp Id",
        value: "",
        label: "Company Emp Id",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "Company Emp Id",
        derivedValue:
          "companyEmpId=companyEmpId=companyEmpId=companyEmpId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "companyEmpId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "companyEmpId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value, getDataFromServer) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.leaveHistories}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            getDataFromServer();
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },

    {
      name: "LeaveCreate",
      action: saveDataToServer,
      options: [
        {
          label: "LeaveCreate",
          field: "type",
          value: "LeaveCreate",
          show: rolePermission?.actions?.includes("Update") ? true : false,
          multiple: true,
        },
      ],
    },
    {
      name: "LeaveUpdated",
      action: saveDataToServer,
      options: [
        {
          label: "LeaveUpdated",
          field: "type",
          value: "LeaveUpdated",
          show: rolePermission?.actions?.includes("Update") ? true : false,
          multiple: true,
        },
      ],
    },
  ];

  return (
    <span>
      {
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={true}
          // globalSearch={'Display Name/Email'}
          // type='LeaveHistories'
          // apiUrl={apiCalls.LeaveHistories}
          // exportRequried={true}
          printRequried={true}
          actionsTypes={actionTypes}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.leaveHistories}
          globalSearch={
            "employeeName/type/numberOfDays/afterLeaves/companyEmpId"
          }
          displayName="Leave Histories"
          type="LeaveHistories"
          routeTo={apiCalls.leaveHistories}
          displayViewOfForm="sideForm"
          sideFormLeftOrRight="left"
          apiResponseKey={apiCalls.leaveHistories?.toLowerCase()}
          apiUrl={apiCalls.leaveHistories}
          selectedId={params.id}
          displayActionsInTable={"actions"}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="leaveHistories"
          apiUrl={apiCalls.leaveHistories}
        />
      ) : null}
    </span>
  );
};

export default LeaveHistories;