const commonSampleFilePath = "http://localhost:3000/images/sample";

const configSampleFiles = {
  employees:
    "https://tracktestapi.dosystemsinc.com/images/sample_employees_bulk_upload_file.csv",
  activities:
    "https://tracktestapi.dosystemsinc.com/images/sample_activities_bulk_upload_file.csv",
  emailtemplates:
    "https://tracktestapi.dosystemsinc.com/images/sample_emailtemplates_bulk_upload_file.csv",
  roles:
    "https://tracktestapi.dosystemsinc.com/images/sample_roles_bulk_upload_file.csv",
  uploadhistories:
    "https://tracktestapi.dosystemsinc.com/images/sample_uploadhistories_bulk_upload_file.csv",
  emailstatuses:
    "https://tracktestapi.dosystemsinc.com/images/sample_emailstatuses_bulk_upload_file.csv",
  projects:
    "https://tracktestapi.dosystemsinc.com/images/sample_projects_bulk_upload_file.csv",
  tasks:
    "https://tracktestapi.dosystemsinc.com/images/sample_tasks_bulk_upload_file.csv",
  timesheets:
    "https://tracktestapi.dosystemsinc.com/images/sample_timesheets_bulk_upload_file.csv",
  leaves:
    "https://tracktestapi.dosystemsinc.com/images/sample_leaves_bulk_upload_file.csv",
  issues:
    "https://tracktestapi.dosystemsinc.com/images/sample_issues_bulk_upload_file.csv",
  dailystatuses:
    "https://tracktestapi.dosystemsinc.com/images/sample_dailystatuses_bulk_upload_file.csv",
  dashboards:
    "https://tracktestapi.dosystemsinc.com/images/sample_dashboards_bulk_upload_file.csv",
  attendences:
    "https://tracktestapi.dosystemsinc.com/images/sample_attendences_bulk_upload_file.xlsx",
  leavehistories:
    "https://tracktestapi.dosystemsinc.com/images/sample_leavehistories_bulk_upload_file.csv",
  publicholidays:
    "https://tracktestapi.dosystemsinc.com/images/sample_publicholidays_bulk_upload_file.csv",
  inventories:
    "https://tracktestapi.dosystemsinc.com/images/sample_inventories_bulk_upload_file.csv",
  inventoryhistories:
    "https://tracktestapi.dosystemsinc.com/images/sample_inventoryhistories_bulk_upload_file.csv",
  appraisals:
    "https://tracktestapi.dosystemsinc.com/images/sample_appraisals_bulk_upload_file.csv",
  presentations:
    "https://tracktestapi.dosystemsinc.com/images/sample_presentations_bulk_upload_file.csv",
  clients:
    "https://tracktestapi.dosystemsinc.com/images/sample_clients_bulk_upload_file.csv",
  setups:
    "https://tracktestapi.dosystemsinc.com/images/sample_setups_bulk_upload_file.csv",
  sprints:
    "https://tracktestapi.dosystemsinc.com/images/sample_sprints_bulk_upload_file.csv",
  releases:
    "https://tracktestapi.dosystemsinc.com/images/sample_releases_bulk_upload_file.csv",
};
export default configSampleFiles;
