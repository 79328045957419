import React from "react";
import { Calendar } from 'primereact/calendar';
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";


const DoDateField = (props) => {
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;    
    const name = props.name;
    const placeholder = props.placeholder;
    const selectionMode = props.selectionMode;
    const showButtonBar = props.showButtons;
    let field = props.field;
    let timeOnly = props.timeOnly
    let formType = props.formType;
    let minDates = props.minDate ? props.minDate : '';
    let maxDates = props.maxDate ? props.maxDate : '';
    const isDisable = props.isDisable ? true : false

    let markRequired = () => {
        return (
          <FontAwesomeIcon
            color='red'
            icon={faAsterisk}
            style={{ width: '7px',marginBottom:"5px",marginLeft:"2px" }}
          />
        );
      };

   

    return (
      <div className="flex flex-column ">
        <div className="flex align-center">{" "}{/* Added a wrapper div with flex class */}
          <label htmlFor={id} className="text-capitalize">{label}</label>
          {props.markReq && props.markReq === true && markRequired()}
        </div>

        <Calendar
           showButtonBar={showButtonBar ? true : false}
           selectionMode={selectionMode ? selectionMode : 'single'}
          inputId={field.name}
          value={field.value && !isNaN(new Date(field.value).getTime())? new Date(field.value): null}
          onChange={(e) => field.onChange(e.value)}
          dateFormat="dd/mm/yy"
          placeholder={placeholder}
          timeOnly={timeOnly}
          showIcon
          minDate={minDates ? new Date(minDates) : null}
          maxDate={maxDates ? new Date(maxDates) : null}
          disabled={isDisable}
        />
        <small className="text-danger ">{props.fieldState.invalid ? props.errors[props.name]?.message : ""}</small>
      </div>
    )

}

export default DoDateField;