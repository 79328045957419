import React, { useRef } from "react";
import { FileUpload } from 'primereact/fileupload';
import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../../containers/UI/ToasterMessage/toasterMessage';
import config from '../../../config/config';
import configImages from '../../../config/configImages';
import { Link } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';

const DoFileUpload = (props) => {
    const fileInputRef = useRef(null);

    const id = props.id ? props.id : props.name;
    let label = props.label ? props.label : '';
    let newFiles = !Array.isArray(props.field.value) ? [] : props.field.value;
    let isMultiple = props.isMultiple ? props.isMultiple : false;
    let mode = props.isMultiple && props?.forKanban ? false : props.isMultiple
    let maxFileSize = props.maxFileSize ? props.maxFileSize : 2097152; // 2 MB
    let type = props.type === "Employees" ? "Employee" : props.type;
    const maxFiles = 5;

    const onSelect = (e) => {
        const selectedFiles = isMultiple ? e.files : e.files[0];
        const oversizedFiles = [e.files[0]].filter(file => file.size > maxFileSize);

        if (oversizedFiles.length > 0) {
            const bytesPerMB = 1024 * 1024;
            showToasterMessage(`File size should be less than ${maxFileSize / bytesPerMB} MB`, 'error');
            clearFileUpload();
        } else {
            uploadFilesToServer(selectedFiles);
        }
    }

    const upload = (e) => {
        const files = e.files;
        if (files.length > maxFiles) {
            showToasterMessage(`You can only upload ${maxFiles} files`, 'error')
            const fileInput = document.querySelector('.p-fileupload-content');
            if (fileInput) {
                fileInput.style.display = 'none';
            }
        } else {
            uploadFilesToServer(isMultiple ? e.files : e.files[0]);
        }
    }

    const uploadFilesToServer = (data) => {
        fetchMethodRequest('POST', props.url, data, 'upload', isMultiple, props?.forKanban)
            .then(async (response) => {
                if (response && response.respCode) {
                    let fileName = response?.fileName;
                    //Setting field value in form
                    if (props.field && !isMultiple) {
                        props.field.onChange([fileName[0].name])
                    } else {
                        let newFiles = fileName.map(file => file.name);
                        let existingFiles = props.field.value || [];
                        let allFiles = [...existingFiles, ...newFiles];
                        if (props.field) {
                            props.field.onChange(allFiles)
                        }
                        if (props?.handleSave) {
                            props.handleSave(props.item,allFiles)
                        }
                        clearFileUpload();
                    }
                    if (props.type === 'profile') {
                        let data = JSON.parse(localStorage.getItem('loginCredentials'))
                        data.photo = fileName;
                        localStorage.setItem('loginCredentials', JSON.stringify(data))
                    }
                    showToasterMessage(response.respMessage, 'success');
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                } else {
                    showToasterMessage('upload failed')
                }
            }).catch((err) => {
                return err;
            });
    }

    const checkImg = (img) => {
        if (img) {
            return config.allFileFormats.some(x => img.toLowerCase().includes(x));
        }
        return false;
    };

    const checkVid = (img) => {
        if (img) {
            return config.videoFormats.some(x => img.toLowerCase().includes(x));
        }
        return false;
    };

        const deleteFile = (img) => {
            // if (img) { // commented the condition for now beacuse need to delete the "" empty records.
                let allFiles = newFiles.filter(file => file !== img)
                props.field.onChange(allFiles);
                clearFileUpload(allFiles);
            // }
        };

    const clearFileUpload = (allFiles) => {
        if (fileInputRef.current) {
            fileInputRef.current.clear()
        }
        if (props?.handleSave) {
            props.handleSave(props.item,allFiles)
        }
    };

    const chooseOptions = { icon: 'pi pi-fw pi-paperclip', iconOnly: true, className: 'custom-file-upload-btn custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-file-upload-btn custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-file-upload-btn custom-cancel-btn p-button-danger p-button-rounded' };

    return (
        <div className='d-flex flex-column'>
            <div className="flex align-center">
                <label htmlFor={id} className="text-capitalize">{label}</label>
            </div>
            <Tooltip target=".custom-choose-btn" content="Choose File" position="bottom" />
            <Tooltip target=".custom-upload-btn" content="Upload File" position="bottom" />
            <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />
            <FileUpload
                ref={fileInputRef}
                mode={mode ? 'advanced' : 'basic'}
                name={props.name ? props.name : 'file'}
                accept={props.acceptType ? props.acceptType : '*'}
                maxFileSize={maxFileSize}
                onSelect={ props?.forKanban || !isMultiple ? onSelect : ''}
                customUpload
                uploadHandler={upload}
                multiple={isMultiple ? isMultiple : false}
                chooseLabel={null}
                chooseOptions={chooseOptions} 
                uploadOptions={uploadOptions} 
                cancelOptions={cancelOptions}
            />
            <div>
                {props?.forKanban ? null : newFiles && newFiles.length > 0 && (
                    <div className="upload-images">
                        <div className="row">
                            {newFiles.map((img, index) => (
                                <div key={index} className="col-md-6 mb-3">
                                    <div className="d-flex">
                                        <div style={{ flex: 1 }}>
                                            {props.type === 'profile' ? (
                                                <Link to={`${config.profileUrl}${img}`} target="_blank">
                                                    <img src={config.profileUrl + img}
                                                        style={{ width: '100%', height: '80px', objectFit: 'cover', borderRadius: '8px' }} alt=""/>
                                                </Link>
                                            ) : props?.forKanban ? null : checkImg(img) && props.acceptType === "image/*, video/*" ? (
                                                    <Link to={`${config.imgUrl}${type.toLowerCase()}/${img}`} target="_blank">
                                                        {checkVid(img) ?
                                                            <img title={img} src={configImages?.[img?.split('.')?.[img?.split('.')?.length - 1]]}
                                                                style={{ width: '100%', height: '80px', objectFit: 'cover', borderRadius: '8px' }} alt="" /> :
                                                            <img src={config.imgUrl + `${type.toLowerCase()}/` + img}
                                                                style={{ width: '100%', height: '80px', objectFit: 'cover', borderRadius: '8px' }} alt="" />
                                                        }
                                                    </Link>
                                            ) : (
                                                <a href={`${config.imgUrl}${type.toLowerCase()}/${img}`} target='_blank'>
                                                    <img title={img} src={configImages?.[img?.split('.')?.[img?.split('.')?.length - 1]]}
                                                        style={{ width: '100%', height: '100px', objectFit: 'contain', borderRadius: '8px' }} alt="" />
                                                </a>
                                            )}
                                        </div>
                                        {props?.forKanban ? null : <div className="d-flex justify-content-end align-items-start mt-2">
                                            <i
                                                className="pi pi-trash text-danger"
                                                onClick={() => deleteFile(img)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </div>}
                                        
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>

            {/* <small className="text-danger " style={{ marginTop: "5px" }}>{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small> */}
        </div>
    );
};
export default DoFileUpload;