import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import fetchMethodRequest from '../../../../config/service';
import { useParams } from "react-router";
import dateFormats from '../../../UI/FormatDate/formatDate';
import config from '../../../../config/config';
import { Link } from 'react-router-dom'
import { Skeleton } from 'primereact/skeleton';
import { Message } from 'primereact/message';
import AddNotes from "../../CommonModals/AddNotesModal";
import configImages from "../../../../config/configImages";
import moment from 'moment';

const Dashboards = (props) => {
  const [birthDays, setBirthDays] = useState([]);
  const [taskData, setTaskData] = useState();
  const [leaves, setLeaves] = useState([]);
  const [leaveBalance, setLeaveBalance] = useState('');
  const [timeSheetDetails, setTimeSheetDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [meetingNotes, setMeetingNotes] = useState();
  let [serialNumber, setSerialNumber] = useState(1);
  const [timesheetEnabledEmployees, setTimesheetEnabledEmployees] = useState('');

  let loginRole = JSON.parse(localStorage.getItem('loginCredentials'))

  const params = useParams();

  useEffect(() => {
    getCountData();
    getLeavesData();
    getMeetingNotes();
  }, []);

  const getCountData = () => {
    setIsLoading(true);
    fetchMethodRequest('GET', `dashboards`)
      .then(async (response) => {
        if (response) {
          setLeaveBalance(response.leaveBalance)
          if (response && response.birthDay && response.birthDay.length > 0) {
            setBirthDays(response.birthDay);
          }
          if (response && response.totalTasks) {
            setTaskData(response.totalTasks)
            setIsLoading(false);
          }
          if (response && response.unFilledTimesheets) {
            setTimeSheetDetails(response.unFilledTimesheets)
            setIsLoading(false);
          }
          if (response && response.enablePreviousTimesheet && response.enablePreviousTimesheet.length > 0) {
            const maxDisplayCount = 3;
            const displayNames = response.enablePreviousTimesheet.map(item => item.displayName).filter(name => name !== loginRole.displayName);

            if (displayNames.length > maxDisplayCount) {
              const visibleNames = displayNames.slice(0, maxDisplayCount).join(', ');
              const remainingCount = displayNames.length - maxDisplayCount;
              setTimesheetEnabledEmployees(`Filling timesheets for previous days is enabled for ${visibleNames} and ${remainingCount} more`)
            } else {
              setTimesheetEnabledEmployees(`Filling timesheets for previous days is enabled for ${displayNames.join(', ')}`)
            }
          }
        }
      }).catch((err) => {
        return err;
      });
  }

  const getLeavesData = () => {
    setIsLoading(true);
    let url;
    let today = new Date();
    const inputDate = dateFormats.formatDateForFunnelFilter(today, config.dayYearDateFormat);
    let filterObj = {
      'sortfield': 'fromDate', 'direction': 'desc',
      "criteria": [{ 'key': 'toDate', 'value': inputDate, 'type': 'gte' }]
    };
    url = 'leaves?type=DashBoard' + '&filter=' + JSON.stringify(filterObj) + '';
    return fetchMethodRequest('GET', url)
      .then(async (response) => {
        if (response && response.leaves && response.leaves.length > 0) {
          response.leaves.forEach((leave) => {
            leave.fromDate = dateFormats.formatDate(leave.fromDate, config.dateDayMonthFormat);
          })
          setLeaves(response.leaves)
          setIsLoading(false);
        }
      }).catch((err) => {
        return err;
      });
  }

  const getMeetingNotes = () => {
    setIsLoading(true);
    let url;
    let filterObj = { "limit": 50, "page": 1, "criteria": [{ "key": "status", "value": ["Active", "Pending"], "type": "in" }], "sortfield": "created", "direction": "desc" }
    url = 'projects?filter=' + JSON.stringify(filterObj);
    return fetchMethodRequest('GET', url)
      .then(async (response) => {
        if (response && response.projects) {
          setMeetingNotes(response.projects)
          setIsLoading(false);
        }
      }).catch((err) => {
        return err;
      });
  }

  const isUpdateEditorValues = (values) => {
    values = values.replace(/-/g, '');
    const tempElement = document.createElement('div');
    tempElement.innerHTML = values;
    return (tempElement.textContent.replace(/<p>/gi, ' ').replace(/<\/p>/gi, ' '));
  }

  let maxLength = timeSheetDetails ? Math.max(...Object.values(timeSheetDetails).map(arr => arr.length)) : null;

  return (
    <div className="dashboard">
      {/* {(loginRole.roleType === 'Manager' || loginRole.roleType === 'Admin') && timesheetEnabledEmployees ?
        <div className="card flex justify-content-center">
          <Message severity="error" text={timesheetEnabledEmployees} />
        </div> : null} */}
      <Row style={{ margin: "5px" }}>
        <Col md={12}>
          <h3 className="page-title" style={{ marginBottom: '15px' }}><b style={{ fontSize: '20px' }}>{'Dashboard'}</b></h3>
        </Col>
      </Row>

      <Row className="d-flex align-items-stretch">
        {(birthDays && birthDays.length > 0) ?
          <Col xs={12} md={4} lg={6} xl={6}>
            <div className="row birthDayBackground mb-3 mx-1 p-3 d-flex align-items-center">
              <div className='col-6'>
                {birthDays.map((item, index) => {
                  return (
                    <div key={index} style={{ margin: "10px" }}>
                      <span style={{ color: '#f17038' }}>{'Happy Birthday! 🎉🎂😊'}</span><br />
                      <span style={{ color: 'blue' }}>{item.firstName + " " + item.lastName}</span>
                    </div>
                  )
                })}
              </div>
              <div className="col-6 d-flex justify-content-center">
                <img src={configImages.happyBirthdayImage} alt="Happy Birthday" />
              </div>
            </div>
          </Col>
          : null}


        {leaveBalance || leaveBalance === 0 ?
          <Col xs={12} md={4} lg={6} xl={2} className="d-flex align-items-stretch">
            <Card className="w-100 h-100">
              <CardBody className="dashboard__card-widget-small text-center d-flex flex-column justify-content-center">
                <div className="card__title">
                  <h6 className="bold-text">{'LEAVE BALANCE'}</h6>
                </div>
                <Link to="/leaves" style={{ textDecoration: 'none' }}>
                  <div className="dashboard__total">
                    <p className="dashboard__total-stat">
                      <span style={{ color: "#007ad9" }}>{leaveBalance}</span>
                    </p>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
          : null}
      </Row>

      <Row>
        <Col lg={6} xl={6}>
          <Card className="dashBoardCardWithScroll">
            <CardBody>
              <div className="card__title card__title__spacting">
                <h5 className="bold-text">{'MISSING TIMESHEETS'}</h5>
                <div className="indicator">
                  <span className="indicator-box timesheetLessThanNine"></span>
                  <span>Time-sheet hours &lt; 9</span>
                </div>
              </div>
              <Table responsive hover className="table--head-accent table-fixed">
                {isLoading ? (
                  <Skeleton className="mb-2" height="2.5rem" borderRadius="10px" />
                ) : (
                  <thead>
                    <tr>
                      <th style={{ width: 8 + '%' }}>#</th>
                      <th style={{ width: 37 + '%' }}>{'Name'}</th>
                      <th style={{ width: 55 + '%' }}>{'Date'}</th>
                    </tr>
                  </thead>)}

                {isLoading ? (
                  <Skeleton className="mb-2" height="190px" borderRadius="10px" />
                ) : (
                  <tbody>
                    {loginRole && loginRole.roleType && (loginRole.roleType === 'Manager' || loginRole.roleType === 'Admin') ? (
                      timeSheetDetails && Object.keys(timeSheetDetails).length > 0 ? (
                        Object.keys(timeSheetDetails).map((key, i) => {
                          const missingDates = timeSheetDetails[key]?.missingTimeSheetDates || [];
                          const empobj = {
                            displayName: timeSheetDetails[key]?.employeeDetails?.displayName,
                            _id: timeSheetDetails[key]?.employeeDetails?._id
                          }
                          const hasLowHours = missingDates.some((day) => day.noofHoursWorked < 9);
                          const row = (
                            <tr key={key} style={{ backgroundColor: hasLowHours ? "#ccdeff" : "transparent" }}>
                              <td style={{ width: '8%' }}>{i + 1}</td>
                              <td style={{ width: '37%', textTransform: "capitalize" }}>{key}</td>
                              <td style={{ width: '55%', color: '#007ad9' }}>
                                {missingDates.map((day, index) => (
                                  <span key={index} style={{ color: "#f17038" }}>
                                    <Link to={`/timeSheets/date/${encodeURIComponent(day.date)}`} state={{ empobj }} style={{ textDecoration: 'none' }}>
                                      {new Date(day.date).getDate()} ({day.noofHoursWorked} hrs)
                                    </Link>
                                    {index < missingDates.length - 1 && ', '}
                                  </span>
                                ))}
                              </td>
                            </tr>
                          );
                          return row;
                        })
                      ) : (
                        <tr className="bold-text">
                          <td style={{ width: '100%', textAlign: 'center', paddingTop: '95px' }} colSpan="3">
                            {'No Timesheet Updates'}
                          </td>
                        </tr>
                      )
                    ) : (
                      timeSheetDetails && Object.keys(timeSheetDetails).length > 0 ? (
                        Object.keys(timeSheetDetails).map((key, i) => {
                          const missingTimeSheetDates = timeSheetDetails[key]?.missingTimeSheetDates || [];
                          return missingTimeSheetDates.map((date, j) => {
                            const serialNumber = i * missingTimeSheetDates.length + j + 1;
                            const hasLowHours = missingTimeSheetDates.some((day) => day.noofHoursWorked < 9);
                            const row = (
                              <tr key={`${i}-${j}`} style={{ backgroundColor: hasLowHours ? "#ccdeff" : "transparent" }}>
                                <td style={{ width: '8%' }}>{serialNumber}</td>
                                <td style={{ width: '37%', textTransform: "capitalize" }}>
                                  {key}
                                </td>
                                <td style={{ width: '55%', color: '#007ad9' }}>
                                  <Link to={`/timeSheets/date/${encodeURIComponent(date.date)}`} style={{ textDecoration: 'none' }}>
                                    {date.date} ({date.noofHoursWorked} hrs)
                                  </Link>
                                </td>
                              </tr>
                            );
                            return row;
                          });
                        }).flat()
                      ) : (
                        <tr className="bold-text">
                          <td style={{ width: '100%', textAlign: 'center', paddingTop: '95px' }} colSpan="3">
                            {'No Timesheet Updates'}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                )}
              </Table>
            </CardBody>
          </Card>
        </Col>

        <Col lg={6} xl={6} >
          <Card className="dashBoardCardWithScroll">
            <CardBody>
              <div className="card__title card__title__spacting">
                <h5 className="bold-text">{'RECENT LEAVES'}</h5>
                <div className="indicator">
                  <span className="indicator-box employeeOnLeave"></span> Employee on Leave
                </div>
              </div>
              <Table className="table--bordered table--head-accent table-fixed" responsive hover>
                {isLoading ? (
                  <Skeleton className="mb-2" height="2.5rem" borderRadius="10px" />
                ) : (
                  <thead>
                    <tr>
                      <th style={{ width: 8 + '%' }}>#</th>
                      <th style={{ width: 40 + '%' }}>{'Employee'}</th>
                      <th style={{ width: 30 + '%' }}>{'From Date'}</th>
                      <th style={{ width: 22 + '%' }}>{'No Of Days'}</th>
                    </tr>
                  </thead>)}

                {isLoading ? (
                  <Skeleton className="mb-2" height="190px" borderRadius="10px" />
                ) : (
                  <tbody>
                    {leaves && leaves.length > 0 ?
                      leaves.map((leave, i) => {
                        const isMatch = leave.employeeName?._id === loginRole?._id;
                        return <tr key={i} style={{ backgroundColor: isMatch ? '#fffccc' : 'transparent' }}>
                          <td style={{ width: 8 + '%' }}>{i + 1} </td>
                          <td style={{ width: 40 + '%', textTransform: "capitalize" }}>{leave.employeeName?.displayName}</td>
                          <td style={{ width: 30 + '%', paddingLeft: '15px' }}>{leave.fromDate}</td>
                          <td style={{ width: 22 + '%', paddingLeft: '50px' }}>{leave.numberOfDays}</td>
                        </tr>
                      })
                      :
                      <tr className="bold-text">
                        <td style={{ width: 100 + '%', textAlign: 'center', paddingTop: '95px' }} colSpan="4">
                          {'No Recent Leaves'}
                        </td>
                      </tr>
                    }
                  </tbody>)}
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={6} xl={6} >
          <Card className="dashBoardCardWithScroll">
            <CardBody>
              <div className="card__title card__title__spacting">
                <h5 className="bold-text">{'ACTIVE TASKS'}</h5>
              </div>
              <Table className="table--bordered table--head-accent table-fixed" responsive hover>
                {isLoading ? (
                  <Skeleton className="mb-2" height="2.5rem" borderRadius="10px" />
                ) : (
                  <thead>
                    <tr>
                      <th style={{ width: 5 + '%' }}>#</th>
                      <th style={{ width: 12 + '%' }}>{'Task ID'}</th>
                      <th style={{ width: 43 + '%' }}>{'Task Name'}</th>
                      <th style={{ width: 20 + '%' }}>{'Sprint'}</th>
                      <th style={{ width: 20 + '%' }}>{'Project'}</th>
                    </tr>
                  </thead>)}

                {isLoading ? (
                  <Skeleton className="mb-2" height="190px" borderRadius="10px" />
                ) : (
                  <tbody>
                    {taskData && taskData.map((item, i) => {
                      const sprintNames = item && item.sprintName && item.sprintName.join(', ');
                      return <tr key={i}>
                        <td style={{ width: 5 + '%' }}>{i + 1} </td>
                        <td style={{ width: 12 + '%', textTransform: "capitalize" }}>{item.taskID}</td>
                        <td style={{ width: 43 + '%', textTransform: "capitalize" }}>{item.name}</td>
                        <td style={{ width: 20 + '%', textTransform: "capitalize" }}>{sprintNames}</td>
                        <td style={{ width: 20 + '%', textTransform: "capitalize" }}>{item.projectName}</td>
                      </tr>
                    })}
                  </tbody>)}
              </Table>
            </CardBody>
          </Card>
        </Col>

        <Col lg={6} xl={6} >
          <Card className="dashBoardCardWithScroll">
            <CardBody>
              <div className="card__title card__title__spacting">
                <h5 className="bold-text">{'Meeting Notes'}</h5>
                <div className="indicator">
                  <span className="indicator-box meetingNotesPass10Days"></span> Meeting Notes pass 10 Days
                </div>
              </div>
              <Table className="table--bordered table--head-accent table-fixed" responsive hover>
                {isLoading ? (
                  <Skeleton className="mb-2" height="2.5rem" borderRadius="10px" />
                ) : (
                  <thead>
                    <tr>
                      <th style={{ width: 8 + '%' }}>#</th>
                      <th style={{ width: 22 + '%' }}>{'Project'}</th>
                      <th style={{ width: 30 + '%' }}>{'Meeting Notes'}</th>
                      <th style={{ width: 20 + '%' }}>{'Date'}</th>
                      <th style={{ width: 20 + '%' }}>{'Created By'}</th>
                    </tr>
                  </thead>)}

                {isLoading ? (
                  <Skeleton className="mb-2" height="190px" borderRadius="10px" />
                ) : (
                  <tbody>
                    {meetingNotes && meetingNotes.map((project, projectIndex) => {
                      project.meetingNotes.sort((a, b) => {
                        const dateA = new Date(a.meetingDate.split("-").reverse().join("-"));
                        const dateB = new Date(b.meetingDate.split("-").reverse().join("-"));
                        return dateB - dateA;
                      });

                      return project.meetingNotes.map((item, i) => {
                        const createdDate = new Date(item.created);
                        const tenDaysAgo = new Date();
                        tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);
                        const isOldMeeting = createdDate < tenDaysAgo && (item.status === "In Discussion" || item.status === "");

                        return (
                          (item.status === "In Discussion" || item.status === "") &&
                          <tr key={`${projectIndex}-${i}`} style={{ backgroundColor: isOldMeeting ? "#CCCCFF" : "transparent" }}>
                            <td style={{ width: 8 + '%' }}>{serialNumber++}</td>
                            <td style={{ width: 22 + '%' }}>
                              <AddNotes
                                item={project}
                                selectedItem={item}
                                getDataFromServer={getMeetingNotes}
                                forDashboard={true}
                              /></td>
                            <td style={{ width: 30 + '%', textTransform: 'capitalize', maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={isUpdateEditorValues(item.projectNotes)}>{isUpdateEditorValues(item.projectNotes)}</td>
                            <td style={{ width: 20 + '%', textTransform: 'capitalize' }}>{item.meetingDate}</td>
                            <td style={{ width: 20 + '%', textTransform: 'capitalize' }}>{item.createdByName}</td>
                          </tr>
                        )
                      });
                    })}
                  </tbody>)}
              </Table >
            </CardBody >
          </Card >
        </Col >
      </Row >
    </div >
  );
};
export default Dashboards;
